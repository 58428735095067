@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Raleway", sans-serif;
    //overflow-y: hidden;
}
@import "./bootstrap";
@import "./components/navbar";
@import "./components/header";
@import "./custom";