body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.questions {
  div {
    margin: 10px 0px;
    font-size: 1.2rem;
  }
}
.benefit {
  border-radius: 25px;
  border: 1px solid white;
  padding: 40px;
  width: 400px;
  margin: 20px;
  cursor: pointer;
  &:hover {
    box-shadow: none;
  }
  -webkit-box-shadow: 1px 2px 25px 10px rgba(230, 244, 241, 0.55);
  -moz-box-shadow: 1px 2px 25px 10px rgba(230, 244, 241, 0.55);
  box-shadow: 1px 2px 25px 10px rgba(230, 244, 241, 0.55);
  text-align: justify;
}

.premios {
  .title {
    font-weight: 700;
    font-size: 1.2em;
  }
  .placement {
    list-style: none;
  }
}
.pictures {
  img:first-child {
    height: 5em;
  }
  img:nth-child(2) {
    height: 9em;
  }
  img:nth-child(3){
    height: 5em;
  }
  img:nth-child(4){
    height: 9em;
  }
}
.first-card-title {
  font-size: 1.5em;
  border: 1px solid $primary;
  width: auto;
  border-radius: 0% 10% 0% 25%;
  cursor: pointer;
}
.second-card-body {
  width: 45em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 10px;
  border: 1px solid $primary;
  text-align: justify;
  -webkit-box-shadow: 1px 2px 25px 10px rgba(247, 147, 52, 1);
  -moz-box-shadow: 1px 2px 25px 10px rgba(247, 147, 52, 1);
  box-shadow: 1px 2px 25px 10px rgba(247, 147, 52, 1);
  font-size: 1.3em;
}

.cards-content {
  border: 1px solid $primary;
  h3 {
    font-size: 2em;
    font-weight: 600;
  }
}

[class^="card-"]{
  .row {
    height: 13em;
    color: white;
  }
}
.card-0 {
  .row {
    background-color: rgb(0, 96, 126);
  }
}
.card-1 {
  align-self: end;
  .row {
    background-color: rgba(0, 67, 97);
  }
}
.card-2 {
  .row {
    background-color: rgba(0, 41, 68);
  }
}

@media (max-width: 768px) {
  .onLineWorld {
    width: 100%;
    overflow-y: hidden;
    img {
      width: 99%;
      margin: 0px auto;
    }
  }
  .pictures {
    img {
      width: 7em;
      object-fit: contain;
    }
    img:first-child {
      height: 4em;
    }
    img:nth-child(2) {
      height: 5em;
    }
    img:nth-child(4) {
      height: 5em;
    }
  }
  .second-card-body {
    width: 25em;
    font-size: 1em;
  }
  [class^="card-"] {
    align-self: center;
    .row{
      height: auto;
      margin: 0px auto;
    }
  }
}
